// import create slice 
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios
import axios from "axios";


export const fetechBrands = createAsyncThunk('carBrandSlicer/fetechBrands' , async()=>{
    const res = await axios.get(`https://shift-dashboard.com/api/car-brands`);
    console.log(res.data.data)
    return res.data.data;
})


export const carBrandSlicer = createSlice({
    initialState: {
        brands: [], 
        status: 'OFF', 
        error: null
    },
    name: 'carBrandSlicer',
    reducers: {},
    extraReducers: (builder)=>{
        builder.addCase(fetechBrands.pending , (state , action)=>{
            state.status = 'loading'
        }).addCase(fetechBrands.fulfilled , (state , action)=>{
            state.status = 'Success';
            state.brands = action.payload;
        }).addCase(fetechBrands.rejected , (state,action)=>{
            state.status = 'Rejected';
            state.error = action.error.message;
        })
    }

})
// Export the reducer
export default carBrandSlicer.reducer;