import { Link } from 'react-router-dom';
import '../css/itembox.css';
import product from '../images/headphone.webp';

function ItemBox(props) {
    return (
        <>
            <div className="item col-2">
                <div className='item-image'>
                    <img src={props.itemImage} alt="image" className='product-image' />
                </div>
                <div className='item-title-pricing'>
                    <div className='item-title'>
                        <p>{props.itemTitle}</p>
                    </div>
                    <div className='item-price'>
                        <p className='price-after-discount'>{props.priceAfterDiscount + '  ر.س'}</p>
                        {props.priceBeforeDiscount && (
                            <div className='d-flex-column justify-content-center align-items-center'>
                                <p>قبل الخصم</p>
                                <p className='price-before-discount'>{props.priceBeforeDiscount + ' ر.س'}</p>
                            </div>
                        )}
                      
                    </div>
                </div>
                {/* <div className='item-btn'>

                    <Link to={`/product-details/${props.uuid}`}><button type="button" className='item-btn-details'>تفاصيل المنتج</button></Link>
                </div> */}
            </div>
        </>
    );
}

export default ItemBox;