function ContactSectionHead(props) {
  return (
    <div className="row p-2 locationBox flex-lg-row flex-md-row flex-sm-column ">
      <div className="col-md-6 callcenterBox">
        <img src={props.img} alt="" className="callcenter" />
      </div>
      <div className="col-md-6 callcenterBoxHeadingTitle justify-content-center align-items-start flex">
        <h3 className="contact-heading mb-4">{props.heading}</h3>
        <p className="contact-body text-wrap m-0">
          {props.body}
        </p>
      </div>
    </div>
  );
}

export default ContactSectionHead;
