import { Link } from "react-router-dom";
import "../css/quicklink.css";

function QuickLinkBox(props) {
  return (
    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
      <Link to={props.url}>
        <div className={"box-" + props.bg}>
          <div className="">
            <h1 className="quick-link-box-heading mb-0">{props.heading}</h1>
            <p className="quick-link-box-desc mb-0">{props.desc}</p>
          </div>

          <img src={props.image} alt="" className="quick-link-box-icon" />
        </div>
      </Link>

    </div>
  );
}

export default QuickLinkBox;
