
import '../css/box.css';
function Box(props) {
    return (
        <div className="box col-md-3 col-sm-12 col-lg-3">
           
            <div className='box-icon-title'>
                <div className="box-title">
                    <p className='m-0'>{props.title}</p>
                </div>
                <div className="box-icon">
                    <img src={props.icon} alt=""/>
                </div>
            </div>
            <div className="box-description">
                <p className='m-0 px-4'>{props.description}</p>
            </div>
        </div>
    );
}

export default Box;