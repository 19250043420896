import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom"; // Import Link for routing
import logo from "../images/logo.svg";
import "../css/navbar.css";
import "../css/logo.css";
import menubar from '../images/menuBar.png';

function Navbar() {
  // State to track if the navbar is collapsed
  const [isCollapsed, setIsCollapsed] = useState(true);

  // Function to toggle the navbar
  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Function to collapse navbar when a link is clicked
  const closeNavbar = () => {
    setIsCollapsed(true);
  };

  return (
    <nav className="navbar nav-underline navbar-expand-lg nav">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={closeNavbar}>
          <img src={logo} alt="Logo" className="logo animate__animated animate__fadeInRight" />
        </Link>
        <button
          className={`navbar-toggler ${isCollapsed ? "collapsed" : ""}`}
          type="button"
          onClick={toggleNavbar} // Toggle on button click
          aria-controls="navbarNavDropdown"
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          <span><img src={menubar} alt="" className="navbar-toggler-icon"/></span>
        </button>
        <div className={`collapse navbar-collapse ${isCollapsed ? "" : "show"}`} id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to={'/'} onClick={closeNavbar} aria-current="page">
                الرئيسية
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                من نحن
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to="/about-us" onClick={closeNavbar}>
                    نبذة
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/questions" onClick={closeNavbar}>
                    أسئلة واستفسارات
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/book-now" onClick={closeNavbar}>
                احجز اونلاين
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={'/our-products'} onClick={closeNavbar}>
                منتجاتنا
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={'/warranty-policy'} onClick={closeNavbar}>
                الضمان
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/our-loaction" onClick={closeNavbar}>
                فرعنا الرئيسي
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                تواصل
              </Link>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to="/contact-us" onClick={closeNavbar}>
                    استفسارات
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/Complaints" onClick={closeNavbar}>
                    شكاوى
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
