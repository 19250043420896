// import create slice 
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import axios
import axios from "axios";


export const fetechOffers = createAsyncThunk('offerSlicers/fetechoffers' , async()=>{
    const res = await axios.get(`https://shift-dashboard.com/api/offers`);
    console.log(res.data.data)
    return res.data.data;
})


export const offerSlicer = createSlice({
    initialState: {
        offers: [], 
        status: 'OFF', 
        error: null
    },
    name: 'offerSlicers',
    reducers: {},
    extraReducers: (builder)=>{
        builder.addCase(fetechOffers.pending , (state , action)=>{
            state.status = 'loading'
        }).addCase(fetechOffers.fulfilled , (state , action)=>{
            state.status = 'Success';
            state.offers = action.payload;
        }).addCase(fetechOffers.rejected , (state,action)=>{
            state.status = 'Rejected';
            state.error = action.error.message;
        })
    }

})
// Export the reducer
export default offerSlicer.reducer;