import '../css/bookservice.css';
import React from 'react';

function BookService(props) {
  const { serviceName, isActive, onClick } = props;

  return (
    <div
      className={`col-lg-2 col-xl-2 col-md-6 col-sm-12 book-service-componnet ${isActive ? 'active' : ''}`}
      onClick={() => onClick(serviceName)}
    >
      {serviceName}
    </div>
  );
}

export default BookService;
