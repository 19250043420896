import { configureStore } from "@reduxjs/toolkit";
import { cartReducer } from "./slicer/bookingCartSlicer";
import offerSlicer from "./slicer/offerSlicer";
import carBrandsSlicer from "./slicer/carBrandsSlicer";




export const store = configureStore({
    reducer: {
        bookingCart: cartReducer,
        offerSlides: offerSlicer ,
        brands: carBrandsSlicer
    }
});