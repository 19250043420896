
// Css
import '../css/offerslider.css';

// Import Reat Packages
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetechOffers } from '../rtk/slicer/offerSlicer';
import { ProgressBar } from 'react-loader-spinner';
import { Swiper, SwiperSlide } from 'swiper/react';

function OfferSlider() {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const { offers, status, error } = useSelector(state => state.offerSlides);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetechOffers());
    }, [dispatch]);

    const goToNext = () => {
        setIsAnimating(false);
        const isLastSlide = currentIndex === offers.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };

    const goToPrevious = () => {
        setIsAnimating(false);
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? 0 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };


    useEffect(()=>{
        setTimeout(()=>{
            setIsAnimating(true)
        } , 300)
    } , [currentIndex]);

    
    useEffect(() => {
        const interval = setInterval(() => {
            goToNext();
        }, 7000);
        return () => clearInterval(interval);
    }, [currentIndex]);

   

    return (
       

        
            <div className="offer-slider-container ">
                
                    <div className='image-slider'>
                        {status === 'loading' ? (
                            <div className='offer-slider-loader'>
                                <ProgressBar
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#9933FF"
                                    ariaLabel="progress-bar-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    />
                            </div>
                        ) : offers.length > 0 ? (
                            
                            
                            <>
                               
                                <img  src={offers[currentIndex]?.offer_image?.file_path} alt="Offer" className="w-100 " style={{ 
                                    transform: isAnimating ? 'translateX(0%)' : 'translateX(100%)',
                                    transition: 'transform 0.2s ease-in' 
                                }}/>
                          
                            
                            <div className='offer_name'>
                                <h2 className='m-0'>
                                    {offers[currentIndex].offer_name}
                                </h2>
                                <p>
                                    {offers[currentIndex].offer_description}
                                </p>
                            </div>
                            
                            </>
                            
                        ) : (
                            <p>No offers available</p>
                        )}
                    </div>
              
                <div className="next-slide" onClick={goToNext}>
                    <ion-icon name="arrow-round-forward"></ion-icon>
                </div>
                <div className="prev-slide" onClick={goToPrevious}>
                    <ion-icon name="arrow-round-back"></ion-icon>
                </div>
            </div>

        
    );
}

export default OfferSlider;
