import SectionHeading from "./SectionHeading";

function PrivacyPolicy() {
    return (

        <div className="container">
            <SectionHeading heading="سياسات الخصوصية" />
            <div className="row">
                <div className="welcome-message">
                    <p className="p-0">شكرًا لزيارتكم موقع SHIFT ولمراجعة سياسة الخصوصية الخاصة بنا. نحن نقدر ثقتكم ونلتزم بحماية خصوصيتكم. توضح هذه السياسة كيف نقوم بجمع واستخدام وحماية معلوماتك الشخصية عند زيارتك لموقعنا على الويب، أو تطبيقات الهواتف المحمولة، أو أي منصات التواصل الاجتماعي التي تمتلكها وتديرها SHIFT (المشار إليها بشكل جماعي باسم “المواقع”).</p>
                </div>
                <div className="privacy-sections">
                    <div className="section">

                        <h1 className="privacy-heading">من نحن</h1>
                        <div className="privacy-details gap-2">
                            <p className="p-0">SHIFT هي علامة تجارية إيطالية معتمدة بشهادة الجودة ISO 9001، متخصصة في التنظيف العميق باستخدام بخار الماء، والتجديد، وإصلاح الداخلية، ودهان الخارجية، وتلميعها، وحمايتها، والعزل الحراري. تفخر شركتنا بالاستخدام النظام البيئي الصديق للبيئة والمنتجات القابلة للتحلل الحيوي والصديقة للبيئة. بفضل خبرتنا التي تزيد عن 10 سنوات، نقدم خدمات عالية الجودة وتم اختيارنا كشريك حصري للعديد من الشركات. تمتد التزامنا بالتميز إلى ممارسات الخصوصية لضمان سرية وأمان معلوماتك الشخصية.</p>
                           

                        </div>
                    </div>
                    <div className="section">

                        <h1 className="privacy-heading">جمع البيانات</h1>
                        <div className="privacy-details gap-2">
                            <p className="p-0">عندما تزورون مواقعنا، تقوم خوادمنا بجمع وتخزين معلومات معينة تقدمها متصفح الويب الخاص بكم، بما في ذلك:</p>
                            <p className="p-0">اسم الجهاز والعنوان الرقمي للإنترنت للكمبيوتر الرئيسي الذي تستخدمونه</p>
                            <p className="p-0">التاريخ والوقت للوصول</p>
                            <p className="p-0">العنوان الرقمي للموقع الإلكتروني المشير إليه</p>
                            <p className="p-0">الصفحات التي تم زيارتها على مواقعنا</p>
                            <p className="p-0">معلومات حول برنامج متصفح الويب الخاص بكم</p>
                            <p className="p-0">نحن نحلل هذه المعلومات لفهم كيفية تفاعل الزوار مع مواقعنا ولتعزيز تجربة المستخدم. بالإضافة إلى ذلك، قد نقوم بجمع معلومات شخصية منكم عندما تتصلون بنا هاتفيًا أو عبر البريد الإلكتروني أو شخصيًا.</p>
                            <p className="p-0">إذا اخترتم التفاعل مع أزرار وسائل التواصل الاجتماعي على مواقعنا، فقد يتم مشاركة نشاطكم مع المنصة الاجتماعية المعنية وفقًا لإعدادات الخصوصية الخاصة بكم.</p>

                        </div>
                    </div>
                    <div className="section">

                        <h1 className="privacy-heading">الأمان</h1>
                        <div className="privacy-details gap-2">
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>

                        </div>
                    </div>
                    <div className="section">

                        <h1 className="privacy-heading">الروابط إلى مواقع الويب الأخرى</h1>
                        <div className="privacy-details gap-2">
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>

                        </div>
                    </div>
                    <div className="section">

                        <h1 className="privacy-heading">تصحيح معلوماتكم الشخصية</h1>
                        <div className="privacy-details gap-2">
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>

                        </div>
                    </div>
                    <div className="section">

                        <h1 className="privacy-heading">قبول سياسة الخصوصية</h1>
                        <div className="privacy-details gap-2">
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>
                            <p className="p-0">سمس</p>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    );
}


export default PrivacyPolicy;