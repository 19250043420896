import React, { useEffect, useState } from 'react';
import ItemBox from './ItemBox';
import Sidebar from './Sidebar';
import '../css/ourproducts.css';
import closeBtn from '../images/closeBtn.png';

function OurProducts() {
  let limit = 9;
  let [posts, setPosts] = useState([]);
  let [pagesCount, setPagesCount] = useState(1);
  let [model, setModel] = useState(false);
  let [search, setSearch] = useState('');
  let [sortOrder, setSortOrder] = useState(''); // حالة ترتيب المنتجات

  let getPosts = async (currentPage) => {
    const res = await fetch(`https://shift-dashboard.com/api/products`);
    const data = await res.json();
    console.log(data);
    return data;
  };

  useEffect(() => {
    const fetchPosts = async (currentPage = 1) => {
      const data = await getPosts(currentPage);
      // const totalData = data.headers.get('x-total-count');
      // let pagesCount = Math.ceil(totalData / limit);
      setPagesCount(pagesCount);
      setPosts(data);
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    if (sortOrder) {
      const sortedPosts = [...posts].sort((a, b) => {
        if (sortOrder === 'minPrice') {
          return parseFloat(a.product_price) - parseFloat(b.product_price);
        } else if (sortOrder === 'maxPrice') {
          return parseFloat(b.product_price) - parseFloat(a.product_price);
        }
        return 0;
      });
      setPosts(sortedPosts);
    }
  }, [sortOrder, posts]);

  const sortPostsByMinPrice = () => setSortOrder('minPrice');
  const sortPostsByPriceDescending = () => setSortOrder('maxPrice');

  function showModel() {
    setModel(true);
  }

  async function whenPageChange(page) {
    let currentPage = page.selected + 1;
    const data = await getPosts(currentPage);
    setPosts(data);
  }

  return (
    <div className="container">
      <div className={model ? "model-show" : "model"}>
        <img src={closeBtn} alt="" className='close-model-btn' onClick={() => {
          setModel(false);
          document.querySelector('.sidebar').style.display = 'none';
        }} />
        <div className='model-content animate__animated animate__fadeInRight'>
          <Sidebar />
        </div>
      </div>
      <div className="row">
        <div className="col-md-9 col-sm-1">
          <div className="search-bar px-4">
            <form className="search-form">
              <input
                type="search"
                name="search"
                placeholder="بحث عن منتج"
                className="search-input"
                onChange={(event) => {
                  setSearch(event.target.value);
                }}
              />
              <div className="dropdown">
                <a
                  className="btn btn-sorting dropdown-toggle"
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  ترتيب حسب
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li>
                    <a className="dropdown-item" href="#" onClick={sortPostsByMinPrice}>
                      الأقل سعر
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#" onClick={sortPostsByPriceDescending}>
                      الأعلى سعر
                    </a>
                  </li>
                </ul>
              </div>
              <button type="button" className="filter-btn-show-on-small-screen d-md-none" onClick={() => {
                showModel();
                document.querySelector('.sidebar').style.display = 'block';
              }}>فلترة</button>
            </form>
          </div>
          <div className="row justify-content-center products-list animate__animated animate__fadeInRight">
            {posts.filter((post) => {
              return search.toLowerCase() === '' ? post : post.product_name.toLowerCase().includes(search.toLowerCase());
            }).map((post) => {
              return (
                <ItemBox
                  key={post.id}
                  itemImage={post.product_image.file_path}
                  itemTitle={post.product_name}
                  priceAfterDiscount={post.product_price}
                  priceBeforeDiscount={parseInt(post.discount) !== 0 ? post.product_price_before_discount : null}
                />
              );
            })}
          </div>
        </div>
        {/* <div className="col-md-3 col-sm-1 justify-content-center">
          <Sidebar />
        </div> */}
      </div>
    </div>
  );
}

export default OurProducts;
