import car1 from "../images/car1.jpg";
import car2 from "../images/car2.jpg";
import car3 from "../images/car3.jpg";

import power from "../images/power.webp";
import bookingIcon from "../images/bookingIcon.png";
import workIcon from "../images/workIcon.png";
import retoutch from "../images/retoutch.webp";
import "../css/home.css";
import Loader from "./Loader";

import { useEffect, useState } from "react";
import SectionHeading from "./SectionHeading";
import ItemBox from "./ItemBox";
import ServiceBox from "./ServiceBox";
import QuickLinkBox from "./QuickLinkBox";
import { Link } from "react-router-dom";
import OfferSlider from "./OfferSlider";

function Home() {
  let [loading, setloading] = useState(false);

  useEffect(() => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
    }, 3200);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <OfferSlider/>
          <div className="container">
            <SectionHeading heading="تجربة فريدة" />
            <div className="row flex justify-content-evenly gap-4 ">
       
            <QuickLinkBox  heading="إحجز الأن" desc="تمتع بتجربة فريدة مع SHIFT" bg="purble" image={bookingIcon} url="/book-now"/>
            {/* <QuickLinkBox  heading="أعمالنا" desc="شاهد جميع أعمال SHIFT" bg="white" image={workIcon} url="/our-works"/> */}
            <QuickLinkBox  heading="أعمالنا" desc="شاهد جميع أعمال SHIFT" bg="white" image={workIcon}/>

            {/* <SectionHeading heading="أحدث منتجاتنا" />
            <div className="row justify-content-center gap-2 animate__animated animate__fadeInRight">

            <ItemBox itemImage={power} itemTitle={'ملمع زجاج'} priceAfterDiscount={50} priceBeforeDiscount={80}/>
            <ItemBox itemImage={power} itemTitle={'ملمع زجاج'} priceAfterDiscount={50} priceBeforeDiscount={80}/>
            <ItemBox itemImage={power} itemTitle={'ملمع زجاج'} priceAfterDiscount={50} priceBeforeDiscount={80}/>
            <ItemBox itemImage={power} itemTitle={'ملمع زجاج'} priceAfterDiscount={50} priceBeforeDiscount={80}/>
              

            </div> */}
            {/* <SectionHeading heading="خدمتنا" />
            <div className="row gap-3 animate__animated animate__fadeInRight justify-content-center">
              
              <ServiceBox image={retoutch}  serviceTitle="خدمة تلميع" serviceWarranty="ضمان 4 سنوات"/>
              <ServiceBox image={retoutch}  serviceTitle="خدمة عزل" serviceWarranty="ضمان 5 سنوات"/>
              <ServiceBox image={retoutch}  serviceTitle="خدمة نانو" serviceWarranty="ضمان 4 سنوات"/>
              <ServiceBox image={retoutch}  serviceTitle="خدمة افلام حماية" serviceWarranty="ضمان 4 سنوات"/>


            </div> */}
            </div>
           
          </div>

        </>
      )}
    </>
  );
}

export default Home;
